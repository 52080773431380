@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

*,
html,
body {
  font-family: 'Manrope', sans-serif;
}

.iconHover {
  cursor: pointer;
  transform: scale(1);
  transition: all ease-in-out 0.3s;
}

.iconHover:hover {
  transform: scale(1.3);
  transition: all ease-in-out 0.3s;
}

::-webkit-scrollbar-thumb {
  background-color: #f9a600 !important;
}

::-webkit-scrollbar {
  background-color: #192227 !important;
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-corner {
  background-color: #192227;
}

@keyframes loopArrow {
  0% {
    left: 0rem;
  }

  25% {
    left: 1rem;
  }

  50% {
    left: -1rem;
  }

  75% {
    left: 1rem;
  }

  100% {
    left: 0rem;
  }
}

@keyframes arrowTopAndBottom {
  0% {
    left: -100%;
    bottom: -100%;
  }

  20% {
    left: 0%;
    bottom: 0%;
  }

  80% {
    left: 0%;
    bottom: 0%;
  }

  100% {
    left: 100%;
    bottom: 100%;
  }
}

.topBar {
  height: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  transform: scale(1);
  transition: all ease-in-out 0.3s;
}

.topBar:hover {
  height: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  transition: all ease-in-out 0.3s;
}


@keyframes logoShow {
  0% {
    top: -2vw;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: 0.3vw;
  }
}


img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.fVqxjM {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  filter: saturate(2);
  width: 5.5vw;
  height: 5.5vw;
  overflow: hidden;
  background: radial-gradient(95.11% 95.11% at 36.64% 4.89%, rgb(42, 208, 202) 0%, rgb(255, 255, 254) 22.92%, rgb(255, 211, 255) 46.88%, rgb(214, 218, 255) 68.23%, rgb(191, 251, 233) 87.5%, rgb(154, 223, 255) 100%) 0% 0% / 100% 100% no-repeat;
  border-radius: 0.8vw;
}


.fVqxjM-phone {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  filter: saturate(2);
  width: 15vw;
  height: 15vw;
  overflow: hidden;
  background: radial-gradient(95.11% 95.11% at 36.64% 4.89%, rgb(42, 208, 202) 0%, rgb(255, 255, 254) 22.92%, rgb(255, 211, 255) 46.88%, rgb(214, 218, 255) 68.23%, rgb(191, 251, 233) 87.5%, rgb(154, 223, 255) 100%) 0% 0% / 100% 100% no-repeat;
  border-radius: 3vw;
}


.bDWlMR {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0.9;
  top: 0px;
  left: 0px;
  background: conic-gradient(from 0deg at 50% 50%, rgba(0, 0, 0, 0.35) -24.66deg, rgba(255, 255, 255, 0.35) 0.25deg, rgba(0, 0, 0, 0.35) 50.63deg, rgba(0, 0, 0, 0.35) 51.97deg, rgba(255, 255, 255, 0.35) 88.12deg, rgba(0, 0, 0, 0.35) 142.5deg, rgba(255, 255, 255, 0.35) 196.87deg, rgba(0, 0, 0, 0.35) 256.87deg, rgba(255, 255, 255, 0.35) 300deg, rgba(0, 0, 0, 0.35) 335.2deg, rgba(0, 0, 0, 0.35) 335.34deg, rgba(255, 255, 255, 0.35) 360.25deg) 0% 0% / 100% 100% no-repeat;
}


.color-ro-text {
  background: linear-gradient(to top, #f9a600 33%, #f9a600 33%, #f9a600 50%, #f9a600 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.color-ro {
  background: linear-gradient(to top, #f9a600 33%, #f9a600 33%, #f9a600 50%, #f9a600 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.5;
  filter: blur(1rem);
}