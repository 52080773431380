.content-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: transparent;
    pointer-events: none;
    z-index: 2;
}

.background-mask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: transparent;
    color: black;
    overflow: hidden;
    height: 100vh;
    pointer-events: none;
}

.logo-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    position: absolute;
    left: 2rem;
    top: 0rem;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    background-color: #192227;
    padding: 1.6rem;
    z-index: 99;
    border-radius: 100px;
}

.main-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 65%;
    background-color: white;
    color: black;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 100%;
}

.left-content-inner {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    align-items: start;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    padding-left: 6rem;
}

.scroll-indicator {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    opacity: 0.5;
}

.airplane-wrapper {
    position: relative;
}

.airplane-icon {
    margin-right: 0.5rem;
    top: 0.25rem;
    position: absolute;
}

.dots {
    opacity: 0.4;
}

.title {
    font-size: 80px;
    font-weight: 500;
    max-width: 45rem;
    line-height: 4rem;
}

.subtitle {
    font-size: 30px;
    font-weight: 200;
    max-width: 50rem;
}

.icons {
    font-size: 30px;
    font-weight: 200;
    max-width: 50rem;
    gap: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: -3rem;
}

.icon {
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.icon-ro {
    background-image: url('ro.png');
}

.icon-ue {
    background-image: url('ue.svg');
}

.right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    background-color: transparent;
    color: black;
    overflow: hidden;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 100%;
    pointer-events: none;
}

.right-mask {
    position: absolute;
    width: 2rem;
    background-color: white;
    height: 100%;
    left: 64%;
}

.footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}

.marquee-container {
    position: relative;
    font-size: 30px;
    width: 65%;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 30px;
}

.marquee {
    position: relative;
    font-size: 30px;
    width: 70%;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    flex-direction: column;
}

.marquee-text {
    opacity: 0.3;
}

.arrow {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.initial {
    animation: animateDiv 2s ease-in-out;

    position: relative;
    left: 3vw;
    width: auto;
    bottom: -1.5vw;
    color: white;
    font-size: 2vw;
    font-weight: 500;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-bottom: 0.5vw;
    border-radius: 10vw;
    background-color: #192227;
}

.initial-phone {
    animation: animateDiv 2s ease-in-out;

    position: relative;
    width: auto;
    bottom: -10vw;
    color: white;
    font-size: 8vw;
    font-weight: 500;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 1vw;
    border-radius: 10vw;
    background-color: #192227;
}